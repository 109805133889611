<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmTodos"
                          base-table="todos"
                          columnsPrefix="common.column.crm_todos."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.crm_todos"
                          :actions-list="getActions()"
                >
                    <template #cell(id)="row">
                        <a href="#" @click.prevent="$root.$children[0].openModal('todo-modal',{id:row.item.id}, refreshTable)">{{
                                row.item.id
                            }}</a>
                    </template>

                    <template #cell(deadline)="row">
                        {{row.item.deadline | formatDate('DD.MM.YYYY')}}
                    </template>

                    <template #cell(contract_id)="row">
                        <a href="#" @click.prevent="customerContractUrl(row.item.contract_id)">{{
                                row.item.contract_id
                            }}</a>
                    </template>

                    <template #cell(kva_id)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.kva_id.id)"
                           href="#">{{ row.item.kva_id.name }}</a>
                    </template>

                    <template #cell(customer_id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id.id}, refreshTable)">
                            {{ row.item.customer_id.name }}
                        </a>
                    </template>

                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal',{id:row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="deleteTodo(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmTodos',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getTodosTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchTodos', 'getTodosTable');
        },
        customerContractUrl(file) {
            this.$root.openDocument(file, 'customer_contracts')
        },
        deleteTodo(id) {
            this.$removeConfirm('Crm/deleteTodo', {
                id: id
            }, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('Crm/deleteTodos', this.selected.map(row => row.id), this.refreshTable)
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.delete'),
                    icon: 'trash',
                    click: () => {
                        this.deleteSelected();
                    },
                },
            ]
        }
    },
}
</script>